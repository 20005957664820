import * as XLSX from 'xlsx';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {getMonthFromString} from "./dateUtils";

function fitToColumn(arrayOfArray) {
    // get maximum character of each column
    return arrayOfArray.map((a, i) => ({ wch: a.toString().length }));
}

function set_right_to_left(wb/*:Workbook*/) {
    if(!wb.Workbook) wb.Workbook = {};
    if(!wb.Workbook.Views) wb.Workbook.Views = [];
    if(!wb.Workbook.Views[0]) wb.Workbook.Views[0] = {};
    wb.Workbook.Views[0].RTL = true;
}

export const generateCommonPayrollFile = (date, data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Payroll");

    /* fix headers */
    XLSX.utils.sheet_add_aoa(worksheet, [["מספר עובד", "שם מלא", "מקדמה"]], { origin: "A1" });

    /* calculate column width */
    const max_width = data.reduce((w, r) => Math.max(w, r.name.length), 10);
    worksheet["!cols"] = [ { wch: max_width }, { wch: max_width }, { wch: max_width }, { wch: max_width } ];

    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSX.writeFile(workbook, "Payroll" + date + ".xlsx");
}

export const generateShiklulitPayrollFile = (date, data) => {

    let jsonData;

    try {
        const workbook = XLSX.read(data, {type: "base64"});
        const sheet_name_list = workbook.SheetNames;
        jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]);
    } catch (e) {

    }

    const monthRow = getMonthFromString(date)

    let finalArray = []
    jsonData.forEach((item) => {
        finalArray.push({
            "hodesh": monthRow,
            "misparOved": item["מספר עובד"],
            "sugReshuma": "3",
            "kodRehiv": "1",
            "taarif": item["מקדמה"],
            "kamut": "1",
            "": item["שם עובד"]
        })
    })

    const worksheet = XLSX.utils.json_to_sheet(finalArray);
    const workbook = XLSX.utils.book_new();
    set_right_to_left(workbook)
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    /* fix headers */
    XLSX.utils.sheet_add_aoa(worksheet, [["חודש", "מספר עובד", "סוג רשומה", "קוד רכיב", "תעריף", "כמות"]], { origin: "A1" });

    /* calculate column width */
    //const max_width = finalArray.reduce((w, r) => Math.max(w, r.length), 10);
    //worksheet["!cols"] = [ { wch: max_width }, { wch: max_width }, { wch: max_width }, { wch: max_width } ];

    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSX.writeFile(workbook, "Payroll-Shiklulit-" + date + ".xlsx");
}

export const exportErrorsFile = (errorsList) => {
    try {
        let finalErrorsArray = []
        errorsList.forEach((item) => {
            let employeeName = "";
            let govId = "";
            let employeeId = "";
            let metadata = JSON.parse(item['metadata']);
            if(metadata) {
                if(metadata['fullName']) {
                    employeeName = metadata['fullName']
                }
                else {
                    if(metadata['firstName']) {
                        employeeName = metadata['firstName'];
                    }
                    if(metadata['lastName']) {
                        employeeName = employeeName + ' ' + metadata['lastName'];
                    }
                }

                if(metadata['govId']) {
                    govId = metadata['govId']
                }
                if(metadata['employeeId']) {
                    employeeId = metadata['employeeId']
                }
            }
            finalErrorsArray.push({
                "message": item["message"],
                "state": item["state"],
                "companyName": item["companyName"],
                "integrationProviderName": item['integrationProviderName'],
                "count": item['count'],
                "employeeName": employeeName,
                "govId": govId,
                "employeeId": employeeId,
                "metadata": item['metadata']
            })
        })
        const worksheet = XLSX.utils.json_to_sheet(finalErrorsArray);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "ErrorsList");

        /* fix headers */
        XLSX.utils.sheet_add_aoa(worksheet, [["Message", "State", "Company", "Integration Provider", "Error Count", "Employee Name", "Employee Gov Id", "Employee Id", "Error Metadata"]], { origin: "A1" });

        worksheet['!cols'] = fitToColumn(finalErrorsArray);

        XLSX.writeFile(workbook, "errors.xlsx");
    } catch (e) {
    }
}

export const exportRawXlsxFile = (data) => {
    try {
        const workbook = XLSX.read(data, {type: "base64"});
        XLSX.writeFile(workbook, "Payroll.xlsx");
    } catch (e) {
    }
}

export const exportArrayToXlsxFile = (items) => {
    const worksheet = XLSX.utils.json_to_sheet(items);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "transfers");
    XLSX.writeFile(workbook, "transfers.xlsx");
}

export const exportMalamPayrollFile = (date, data) => {
    try {
        const workbook = XLSX.read(data, {type: "base64"});
        const sheet_name_list = workbook.SheetNames;
        let jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]);
        let finalArray = []
        jsonData.forEach((item) => {
            finalArray.push({
                "תעודת זהות": item["תעודת זהות"],
                "שם עובד": item["שם עובד"],
                "מקדמה": item["מקדמה"],
            })
        })

        const newWorksheet = XLSX.utils.json_to_sheet(finalArray);
        const newWorkbook = XLSX.utils.book_new();
        set_right_to_left(newWorkbook)
        XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, "Sheet1");

        /* fix headers */
        XLSX.utils.sheet_add_aoa(newWorksheet, [["תעודת זהות","שם עובד", "מקדמה" ]], { origin: "A1" });

        XLSX.writeFile(workbook, "Payroll-Malam-" + date + ".xlsx");
    } catch (e) {
    }
}

export const exportHilanPayrollFile = (date, data) => {
    try {
        const workbook = XLSX.read(data, {type: "base64"});
        const sheet_name_list = workbook.SheetNames;
        let jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]);
        let finalArray = []
        jsonData.forEach((item) => {
            finalArray.push({
                "תעודת זהות": item["תעודת זהות"],
                "שם עובד": item["שם עובד"],
                "מקדמה": item["מקדמה"],
            })
        })

        const newWorksheet = XLSX.utils.json_to_sheet(finalArray);
        const newWorkbook = XLSX.utils.book_new();
        set_right_to_left(newWorkbook)
        XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, "Sheet1");

        /* fix headers */
        XLSX.utils.sheet_add_aoa(newWorksheet, [["תעודת זהות","שם עובד", "מקדמה" ]], { origin: "A1" });

        XLSX.writeFile(workbook, "Payroll-Hilan" + date + ".xlsx");
    } catch (e) {
    }
}

function groupByCompanyId(data) {
    return Object.values(data.reduce((result, currentItem) => {
        // If the company id is not in the result object, add an empty array
        if (!result[currentItem.companyId]) {
            result[currentItem.companyId] = [];
        }

        // Push the current item into the appropriate array in the result object
        result[currentItem.companyId].push(currentItem);

        // Return the result object for the next iteration
        return result;
    }, {}));
}

const getFileNameForCompanyId = (companyId) => {
    switch (companyId){
        case 1:
            return '1000';
        case 4:
            return '4002';
        case 6:
            return '4000';
        case 7:
            return '4001';
        case 8:
            return '4003';
        case 9:
            return '4004';
        case 10:
            return '4005';
        case 11:
            return '4006';
        case 12:
            return '';
        case 13:
            return '4007';
        case 14:
            return '4008';
        case 15:
            return '4009';
        case 16:
            return '4010';
        case 17:
            return '4011';
        case 18:
            return '4012';
        case 19:
            return '4013';
        case 20:
            return '4014';
        case 21:
            return '4015';
        case 22:
            return '4016';
    }
}

export const generateDailyTransfers = (data) => {

    let zip = new JSZip();

    let companyIds = groupByCompanyId(data);

    companyIds.forEach((transfers) => {
        let finalArray = []
        transfers.forEach((item) => {
            finalArray.push({
                "fullName": item["fullName"],
                "identifier": "",
                "accountId": item["accountId"],
                "bankId": item["bankId"],
                "branchId": item["branchId"],
                "amount": item["amount"],
                "comment": item["transferId"]
            })
        })
        const worksheet = XLSX.utils.json_to_sheet(finalArray);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "DailyTransfers");

        /* fix headers */
        XLSX.utils.sheet_add_aoa(worksheet, [["שם המוטב", "מזהה מוטב", "מספר החשבון", "בנק", "סניף", "סכום", "הערה"]], { origin: "A1" });

        //XLSX.writeFile(workbook, "DailyTransfers-" + transfers[0]['companyId'] + ".xlsx");
        const companyId = Number(transfers[0]['companyId']);
        const filename = "DailyTransfers-" + getFileNameForCompanyId(companyId)  + ".xlsx";
        const wbout = XLSX.write(workbook, {bookType:'xlsx', type:'binary'});

        zip.file(filename, wbout, {binary:true});
    })

    zip.generateAsync({type:"blob"}).then(function(content) {
        saveAs(content, "DailyTransfers.zip");
    });
}

