// MonthlyTransfersChart.js

import React, { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    useColorModeValue,
} from '@chakra-ui/react';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';
import AnalyticsApi from "../../../../api/analytics";

const MonthlyTransfersChart = () => {
    const [data, setData] = useState([]);
    const textColor = useColorModeValue('gray.800', 'white');

    useEffect(() => {
        AnalyticsApi.getMonthlyTransfersData()
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.error('Error fetching monthly transfers data:', error);
            });
    }, []);

    return (
        <Box
            p="6"
            boxShadow="lg"
            bg={useColorModeValue('white', 'gray.700')}
            borderRadius="md"
            w="full"
        >
            <Heading size="md" mb="4" color={textColor}>
                Monthly Growth Chart
            </Heading>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" stroke={textColor} />
                    <YAxis stroke={textColor} />
                    <Tooltip />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="uniqueUsers"
                        name="Unique Users"
                        stroke="purple"
                        strokeWidth={2}
                    />
                    <Line
                        type="monotone"
                        dataKey="numberOfTransfers"
                        name="Number of Transfers"
                        stroke="green"
                        strokeWidth={2}
                    />
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default MonthlyTransfersChart;
