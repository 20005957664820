import React from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import RegistrationsTable from "./components/RegistrationsTable";
import MonthlyTransfersChart from "./components/MonthlyTransfersChart";
import FailedLoginsTable from "./components/FailedLoginsTable";

export default function DataTables() {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid
                mb="20px"
                columns={{ base: 1, md: 2 }}  // 1 column for mobile, 2 for larger screens
                spacing={{ base: "20px", xl: "20px" }}
            >
                {/* Left side - stack MonthlyTransfersChart and FailedLoginsTable */}
                <Box>
                    <MonthlyTransfersChart />
                    <FailedLoginsTable />
                </Box>

                {/* Right side - RegistrationsTable */}
                <Box>
                    <RegistrationsTable />
                </Box>
            </SimpleGrid>
        </Box>
    );
}
