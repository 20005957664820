import React, {Fragment, useEffect, useState} from "react";
import {
    Button,
    ChakraProvider,
    FormControl,
    FormLabel,
    Input, InputGroup, InputLeftElement,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select, Stack, Switch,
    useToast, VStack
} from "@chakra-ui/react";
import {MultiSelect} from 'chakra-multiselect'
import MessageApi from '../../../../api/message'
import PlaceholderInput from "./PlaceholderInput";
import Switcher from "./Switcher";
import FileDropzone from "./FileDropzone";
import Papa from 'papaparse';
import * as XLSX from "xlsx";
import {PhoneIcon} from "@chakra-ui/icons";
import phoneNumberInputs from "./PhoneNumberInputs";
import PhoneNumberInputs from "./PhoneNumberInputs";
import {getCompanies} from "../../../../functions/companiesCache";
import CompanyDropdownSelection from "../../../../functions/CompanyDropdownSelection";

const recipientParamsFromRegistered = ['firstName', 'lastName', 'fullName', 'companyName', 'phoneNumber', 'dateOfBirth', 'jobTitle', 'availableToTransfer'];

const NewMessageModal = (props) => {
    let {
        isOpen, onClose,
        ...rest
    } = props;
    const [messageTitle, setMessageTitle] = useState("");
    const [messageBody, setMessageBody] = useState("");
    const [chosenCompanies, setChosenCompanies] = useState([])
    const [selectedFilters, setSelectedFilters] = useState([])
    const [messageType, setMessageType] = useState("push");
    const [whatsappTemplates, setWhatsappTemplates] = useState([]);
    const [selectedWhatsappTemplateId, setSelectedWhatsappTemplateId] = useState(0);
    const [isWhatsappTemplatesLoading, setIsWhatsappTemplatesLoading] = useState(true);
    const [recipientParamsFromFile, setRecipientParamsFromFile] = useState([]);
    const [parameterIdToParameterName, setParameterIdToParameterName] = useState({})
    const [customInputVisible, setCustomInputVisible] = useState({});
    const [customInputValue, setCustomInputValue] = useState({});
    const [recipientsFromFile, setRecipientsFromFile] = useState([]);

    const [companiesIdCache, setCompaniesIdCache] = useState({});
    const [_companiesOptions, setCompaniesOptions] = useState([]);

    const [isForRegistered, setIsForRegistered] = useState(false);
    const [nonRegisterRecipientType, setNonRegisterRecipientType] = useState("");
    const [recipientsByPhoneNumbers, setRecipientsByPhoneNumbers] = useState([]);
    const [whatsAppMediaUrl, setWhatsAppMediaUrl] = useState("");

    const toast = useToast()

    useEffect(() => {
        if (!isOpen) {
            setMessageTitle("");
            setMessageBody("");
            setChosenCompanies([])
            setSelectedFilters([])
            setParameterIdToParameterName({});
            setCustomInputVisible({});
            setCustomInputValue({});
            setRecipientParamsFromFile([]);
            setWhatsAppMediaUrl("");
        }
    }, [isOpen]);

    const handleTitleChange = (event) => {
        setMessageTitle(event.target.value);
    }

    const handleBodyChange = (value) => {
        setMessageBody(value);
    }

    function getParametersFromText(text) {
        const matches = text.match(/{\s*([^}]+)\s*}/g) || []; // default to empty array if no matches
        return matches.map((val) => {
            return val.replace(/[\{\}\s]/g, '');
        });
    }

    function getChosenCompanyIds(){
        let chosenCompaniesId = [];

        if (chosenCompanies.includes("הכל")) {
            chosenCompaniesId = Object.keys(companiesIdCache);
        } else {
            chosenCompaniesId = chosenCompanies.map((companyName) =>
                findCompanyIdByValue(companiesIdCache, companyName)
            );
        }
        return chosenCompaniesId;
    }
    const findCompanyIdByValue = (companiesIdCache, companyName) => {
        for (let id in companiesIdCache) {
            if (companiesIdCache[id] === companyName) {
                return id;
            }
        }
        return null;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        let message = null;
        if (messageType === 'whatsapp') {
            let template = whatsappTemplates.find(t => t.id === selectedWhatsappTemplateId);
            let parameterNumbers = getParametersFromText(template.preview);
            let maxParameterNumber = Math.max(...parameterNumbers);
            let parameters = [];
            for (let i = 0; i <= maxParameterNumber - 1; i++) {
                let value = parameterNumbers.find(p => Number.parseInt(p) === i + 1);
                parameters[i] = (value && parameterIdToParameterName[value]) || null;
            }
            if (isForRegistered){
                const chosenCompaniesId = getChosenCompanyIds();
                message = {
                    params: {
                        templateName: template.name,
                        parameters: parameters,
                        companiesIds: chosenCompaniesId,
                    },
                    channelType: 2
                }
            } else {
                if(nonRegisterRecipientType === "excel") {
                    message = {
                        params: {
                            templateName: template.name,
                            parameters: parameters,
                            recipientsFromFile: recipientsFromFile,
                        },
                        channelType: 3
                    }
                }
                else {
                    message = {
                        params: {
                            templateName: template.name,
                            parameters: parameters,
                            recipientByPhoneNumber: recipientsByPhoneNumbers,
                        },
                        channelType: 4
                    }
                }
                // to message, the company to filter out
                if(selectedCompanyToFilterOut) {
                    message.companyIdToFilterOut = selectedCompanyToFilterOut;
                }
            }
            // add to message variable the media url
            if(whatsAppMediaUrl){
                message.mediaUrl = whatsAppMediaUrl;
            }
        } else if (messageType === 'push') {
            const chosenCompaniesId = getChosenCompanyIds();
            message = {
                params: {
                    title: messageTitle,
                    body: messageBody,
                    companiesId: chosenCompaniesId
                },
                channelType: 1
            }
        }
        if(message && message.params && selectedFilters && selectedFilters.length > 0){
            message.params.filters = selectedFilters.map((filterName) =>
                filtersIdCash[filterName]
            );
        }
        MessageApi.sendMessage(message).then(res => {
            toast({
                title: `ההודעה נשלחה בהצלחה`,
                position: 'top',
                status: 'success',
                isClosable: false,
            })
            onClose()
        }).catch(e => {
            toast({
                title: `שגיאה בשליחת ההודעה`,
                position: 'top',
                status: 'error',
                isClosable: false,
            })
        })

    }

    useEffect(() => {
        (async () => {
            const cache = await getCompanies();
            setCompaniesIdCache(cache);
        })();
    }, []);

    useEffect(() => {
        setCompaniesOptions([
            { label: "All", value: "הכל" },
            ...Object.keys(companiesIdCache).map(key => ({
                label: key,
                value: companiesIdCache[key],
            }))
        ]);
    }, [companiesIdCache]);

    useEffect(() => {
        (async () => {
            const templates = (await MessageApi.getWhatsappTemplates()).data;
            setWhatsappTemplates(templates);
            setIsWhatsappTemplatesLoading(false);
            setSelectedWhatsappTemplateId(templates[0]?.id);
        })();
    }, []);

    const toggleRegistered = () => {
        setIsForRegistered(!isForRegistered);
    }

    const loadRecipientsFromFile = (file) => {
        const fileType = file.name.split('.').pop();

        if (fileType === 'csv') {
            Papa.parse(file, {
                header: true,
                complete: (results) => {
                    setParameterIdToParameterName({});
                    setRecipientsFromFile(results.data);
                    setRecipientParamsFromFile(Object.keys(results.data[0] || {}));
                }
            });
        } else if (fileType === 'xlsx' || fileType === 'xls') {
            const reader = new FileReader();
            reader.onload = (evt) => {
                const data = new Uint8Array(evt.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setParameterIdToParameterName({});
                setRecipientsFromFile(json)
                const jsonForParams = XLSX.utils.sheet_to_json(worksheet, {header:1});
                setRecipientParamsFromFile(jsonForParams[0] || {});
            };
            reader.readAsArrayBuffer(file);
        } else {
            console.error('Unsupported file type');
        }
    }

    const filtersIdCash = {
        "משתמשים בעלי יתרת משיכה" : "1",
        "מגזר הערבי" : "2",
        "משתמשים שטרם משכו" : "3",
    };


    const _filterOptions = [
        ...Object.keys(filtersIdCash).map((key) => ({
            label: filtersIdCash[key],
            value: key,
            key: filtersIdCash[key]
        })),
    ];

    const handleInputChange = (event) => {
        setRecipientsByPhoneNumbers(event.target.value);
    };
    const [salaryMode, setSalaryMode] = useState("hourly"); // or "monthly"

    const handleToggle = () => {
        setSalaryMode(salaryMode === "hourly" ? "monthly" : "hourly");
    };

    const [selectedCompanyToFilterOut, setSelectedCompanyToFilterOut] = React.useState("")

    const handleCompanySelection = (name, id) =>{
        setSelectedCompanyToFilterOut(id)
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>יצירת הודעה חדשה</ModalHeader>
                <ModalBody>
                    <FormControl>
                        <FormLabel>סוג ההודעה</FormLabel>
                        <Select pr='12px' defaultValue='push' onChange={e => setMessageType(e.target.value)}>
                            <option key='push' value='push'>push</option>
                            <option key='whatsapp' value='whatsapp'>whatsapp</option>
                        </Select>
                    </FormControl>

                    <br/>
                    {messageType === 'whatsapp' && <FormControl>
                        <ChakraProvider>
                            <Switcher isChecked={isForRegistered} handleToggle={toggleRegistered} option1={'registered'}
                                      option2={'non registered'}/> {/* Use the component */}
                        </ChakraProvider>
                    </FormControl>}
                    <br/>

                    <br/>
                    {(messageType === 'push' || (messageType === 'whatsapp' && isForRegistered)) && <MultiSelect
                        options={_companiesOptions}
                        value={chosenCompanies}
                        label='בחירת נמענים'
                        onChange={setChosenCompanies}
                    />}

                    {messageType === 'whatsapp' && !isForRegistered &&
                        <>
                            <FormControl>
                                <FormLabel>נמענים</FormLabel>
                                <Select pr='12px' defaultValue='push' onChange={e => setNonRegisterRecipientType(e.target.value)}>
                                    <option key='excel file' value='excel'>Recipient from excel file</option>
                                    <option key='phone number' value='phone number'>Choose recipient by entering phone number</option>
                                </Select>
                            </FormControl>

                            <FormControl>
                                <FormLabel>להסיר רשומים של חברה</FormLabel>
                                <CompanyDropdownSelection selectedCompany={handleCompanySelection} showAll={true}/>
                            </FormControl>

                        </>
                    }

                    {messageType === 'whatsapp' && !isForRegistered && nonRegisterRecipientType === "phone number" &&
                        <PhoneNumberInputs recipientsByPhoneNumbers={recipientsByPhoneNumbers} setRecipientsByPhoneNumbers={setRecipientsByPhoneNumbers} handleInputChange={handleInputChange}/>
                    }

                    {messageType === 'whatsapp' && !isForRegistered && nonRegisterRecipientType === "excel"&&
                        <FormControl>
                        <FileDropzone onFileLoaded={loadRecipientsFromFile}/>
                    </FormControl>
                    }

                    {messageType === 'whatsapp' &&
                        <Fragment>
                            <FormControl>
                                <FormLabel>בחירת תבנית</FormLabel>
                                {isWhatsappTemplatesLoading ? <span>Loading templates...</span> :
                                    <Fragment>
                                        <Select value={selectedWhatsappTemplateId} onChange={e => {
                                            setSelectedWhatsappTemplateId(e.target.value);
                                            setParameterIdToParameterName({});
                                        }} pr='12px'>
                                            {whatsappTemplates.map(t => <option key={t.id}
                                                                                value={t.id}>{t.name}</option>)}
                                        </Select>
                                        <div>
                                            {whatsappTemplates.find(t => t.id === selectedWhatsappTemplateId)?.preview}
                                        </div>

                                        <FormControl>
                                            <FormLabel>פרמטרים</FormLabel>
                                            {
                                                getParametersFromText(whatsappTemplates.find(t => t.id === selectedWhatsappTemplateId)?.preview)
                                                    .map(p => <span key={p}> {p}:
                            <Select defaultValue="0" pr="12px"
                                    onChange={e => {
                                        if (e.target.value === 'custom') {
                                            setCustomInputVisible(prevState => ({ ...prevState, [p]: true }));
                                        } else {
                                            setParameterIdToParameterName(prevState => ({ ...prevState,[p]:e.target.value}))
                                            setCustomInputVisible(prevState => ({ ...prevState, [p]: false }));
                                        }
                                    }}>
                                <option value='0' disabled hidden>בחר שדה</option>
                                { (isForRegistered ? recipientParamsFromRegistered : recipientParamsFromFile).map(value => <option
                                    key={p + '.' + value} value={value}>{value}</option>)}
                                <option value='custom'>Custom</option>
                            </Select>
                                                        {customInputVisible[p] &&
                                                            <Input
                                                                value={customInputValue[p] || ''}
                                                                onChange={e => {
                                                                    setCustomInputValue(prevState => ({ ...prevState, [p]: e.target.value }));
                                                                    setParameterIdToParameterName(prevState => ({ ...prevState,[p]:e.target.value}))}
                                                                }
                                                            />
                                                        }
                                            </span>)
                                            }


                                            <Input
                                                mt={'10px'}
                                                placeholder={"Media URL"}
                                                onChange={e => {
                                                    setWhatsAppMediaUrl(e.target.value);
                                                }}/>

                                        </FormControl>
                                    </Fragment>
                                }
                            </FormControl>
                        </Fragment>
                    }

                    {messageType === 'push' &&
                        <Fragment>
                            <FormControl id="message-title" isRequired>
                                <FormLabel>כותרת ההודעה</FormLabel>
                                <Input type="text" value={messageTitle} onChange={handleTitleChange}/>
                            </FormControl>
                            <FormControl id="message-body" isRequired>
                                <FormLabel>גוף ההודעה</FormLabel>
                                <PlaceholderInput onMessageBodyChange={handleBodyChange}/>
                            </FormControl>
                        </Fragment>
                    }
                    {(messageType === 'push' || isForRegistered) && <MultiSelect
                        options={_filterOptions}
                        value={selectedFilters}
                        label='מגבלות נוספות'
                        onChange={setSelectedFilters}
                    />}

                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" onClick={onClose}>בטל</Button>
                    <Button colorScheme="blue" onClick={handleSubmit}>שלח הודעה</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default NewMessageModal;
