import {Select} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {getCompanies} from "./companiesCache";

export default function CompanyDropdownSelection(props){

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        (async () => {
            const cache = await getCompanies();
            // Convert the object to an array of objects with `id` and `name` properties
            const companyArray = Object.entries(cache).map(([id, name]) => ({ id, name }));
            setCompanies(companyArray);
        })();
    }, []);

    const handleCompanySelection = (event) => {
        const selectedCompanyName = event.target.value;
        const selectedCompany = companies.find(company => company.name === selectedCompanyName);
        if (selectedCompany) {
            props.selectedCompany(selectedCompanyName, selectedCompany.id);
        }
    }

    return(
    <Select width='190px' pr='12px' onChange={handleCompanySelection} defaultValue={'0'} style={props.style}>
        <option value='0' disabled hidden>
            בחר חברה
        </option>
        {props.showAll ? (
            <option id= "הכל">
                הכל
            </option>
            ) : null}
        {companies.map((option) => (
            <option key={option.id} value={option.name}>
                {option.name}
            </option>
        ))}
    </Select>
    )
}
