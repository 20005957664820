import MockApi from "./mockApi";
import {API} from "./env";
import {axiosInstance} from "./httpClient";


export default class AnalyticsApi {

    static getAnalytics = () => {
        if(MockApi.IsActive) {
            return MockApi.GetDailyAnalytics();
        }

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.get(`${API}/analytics/daily`, config);
    };

    static getGoogleAnalytics = () => {
        if(MockApi.IsActive) {
            return MockApi.getGoogleAnalytics();
        }

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.get(`${API}/analytics/google`, config);
    };

    static getMonthlyTransfersData = () => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.get(`${API}/analytics/monthly`, config);
    };

    static getUsersFailedLogins = () => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.get(`${API}/users/loginAttempts`, config);
    }
}
