import React, { useEffect, useState } from 'react';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Heading,
    useColorModeValue,
    Spinner,
    Text,
} from '@chakra-ui/react';
import AnalyticsApi from '../../../../api/analytics';
import {prettifyTransferDate} from "../../../../functions/dateUtils";

const FailedLoginsTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const textColor = useColorModeValue('gray.800', 'white');
    const tableBg = useColorModeValue('white', 'gray.700');

    useEffect(() => {
        AnalyticsApi.getUsersFailedLogins()
            .then((response) => {
                setData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching failed login attempts data:', error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <Box
                p="6"
                boxShadow="lg"
                bg={tableBg}
                borderRadius="md"
                w="full"
                textAlign="center"
            >
                <Spinner size="xl" />
                <Text mt="4">Loading Failed Login Attempts...</Text>
            </Box>
        );
    }

    return (
        <Box
            p="6"
            boxShadow="lg"
            bg={tableBg}
            borderRadius="md"
            w="full"
        >
            <Heading size="md" mb="4" color={textColor}>
                Failed Login Attempts
            </Heading>
            <Table variant="simple" size="md">
                <Thead>
                    <Tr>
                        <Th color={textColor}>Date</Th>
                        <Th color={textColor}>Gov ID</Th>
                        <Th color={textColor}>Phone Number</Th>
                        <Th color={textColor}>Company External ID</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data.length > 0 ? (
                        data.map((attempt, index) => (
                            <Tr key={index}>
                                <Td>{prettifyTransferDate(attempt.date)}</Td>
                                <Td>{attempt.metadata.govId}</Td>
                                <Td>{attempt.metadata.phoneNumber}</Td>
                                <Td>{attempt.metadata.companyExternalId}</Td>
                            </Tr>
                        ))
                    ) : (
                        <Tr>
                            <Td colSpan={4} textAlign="center">
                                No failed login attempts found.
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>
        </Box>
    );
};

export default FailedLoginsTable;
